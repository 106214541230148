<template>
  <v-progress-linear :active="active" :height="10" indeterminate color="deep-purple-accent-4"></v-progress-linear>

  <Sidebar v-if="$route.meta.requiresAuth" />
  <router-view :class="[ !$route.meta.requiresAuth && 'ma-0 w-100' ]" />
  <v-btn v-if="$route.meta.requiresAuth" class="details-icon" @click="open = !open">
  <v-icon v-if="!open" icon="mdi mdi-menu"></v-icon>
  <v-icon v-else icon="mdi mdi-close"></v-icon>

  </v-btn>
</template>

<script setup>
import { useLoaderStore } from '@/store/loader';
import { useSidebarStore } from '@/store/sidebar';
import { storeToRefs } from 'pinia';
import Sidebar from '@/components/Sidebar.vue'

const loaderStore = useLoaderStore();
const sidebarStore = useSidebarStore()

const { active } = storeToRefs(loaderStore);
const { open } = storeToRefs(sidebarStore)

</script>

<style lang="scss">
@import '@/assets/style.scss';
@import '@/assets/mixins.scss';



.details-icon {
  position: fixed !important;
  bottom: 10px !important;
  right: 10px !important;
  padding: 6px !important;
  background: $SP1900 !important;
  color: $SP1600 !important;
  font-size: 30px !important;
  border-radius: 15px !important;
  height: 60px !important;
  z-index: 9999 !important;
  display: none !important;

  @include maxWidth(767px) {
    display: block !important;
  }

}

.v-application.v-theme--light.v-layout.v-layout--full-height.v-locale--is-ltr {
  @include minWidth(768px) {

    background-color: white !important;
    width: 75%;
    margin-left: 25%;

  }
}
</style>
