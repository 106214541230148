
import { createRouter, createWebHistory } from "vue-router";
import { useLoaderStore } from "@/store/loader";
import { storeToRefs } from "pinia";

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/default/Default.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/login",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "",
        name: "LoginView",
        component: () => import("@/views/LoginView.vue"),
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: "/dashboard",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "",
        name: "DashboardView",
        component: () => import("@/views/DashboardView.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/onboard/:clientId",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "",
        name: "Onboard Invite",
        component: () => import("@/views/OnboardingView.vue"),
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: "/account",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: '',
        name: "Account",
        component: () => import("@/views/AccountView.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/chats',
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: '',
        name: "Chat",
        component: () => import("@/views/ChatView.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/invoices',
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: '',
        name: "Invoices",
        component: () => import("@/views/InvoicesView.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/professionals',
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: '',
        name: "Lawn Pro",
        component: () => import("@/views/LawnProView.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/payments',
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: '',
        name: "Payments",
        component: () => import("@/views/PaymentView.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/properties',
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: '',
        name: "Properties",
        component: () => import("@/views/PropertyView.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/reschedule',
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: '',
        name: "Reschedule",
        component: () => import("@/views/RescheduleView.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/services',
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: '',
        name: "Services",
        component: () => import("@/views/ServiceRequestView.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },

];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL || '/'),
  routes,
});

router.beforeResolve((to, from, next) => {
  const loaderStore = useLoaderStore();
  const { active } = storeToRefs(loaderStore);

  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    active.value = true;
  }
  next();
});
router.onError(() => {
  const loaderStore = useLoaderStore();
  const { active } = storeToRefs(loaderStore);
  active.value = false;
});

router.beforeEach((to, from, next) => {
  const loaderStore = useLoaderStore();
  const { active } = storeToRefs(loaderStore);
  active.value = true;
  if (to.meta.requiresAuth && !localStorage.getItem("token") || (!to.meta.requiresAuth && !localStorage.getItem("token") && to.fullPath === "/")) {
    next("/login");
  } else if (!to.meta.requiresAuth && localStorage.getItem("token")) {
    next("/dashboard")
  }
  else {
    next();
  }
});

router.afterEach(() => {
  const loaderStore = useLoaderStore();
  const { active } = storeToRefs(loaderStore);
  active.value = false;

});
export default router;
