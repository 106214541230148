<template>
  <v-layout>
    <v-navigation-drawer
      :modelValue="open"
      @update:modelValue="open = $event"
      temporary
      @input="handleDrawer"
      :location="isItMobile"
    >
      <div class="text-center mt-6">
        <img src="../assets/images/SP-icon.svg" alt="" class="sidebar-logo" />
        <h1 class="d-none d-sm-block">Schedu<span>Pay</span></h1>
      </div>
      <v-list density="compact" nav class="pa-md-6 pa-2">
        <v-list-item
          v-for="(navigation, index) of navigationRoutes"
          :key="index"
          :prepend-icon="navigation.icon"
          :title="navigation.label"
          :value="navigation.label"
          :to="navigation.path"
        />
      </v-list>
    </v-navigation-drawer>
  </v-layout>
</template>
<script setup>
import { computed } from "vue";
import { useSidebarStore } from "@/store/sidebar";
import { storeToRefs } from "pinia";
const sidebarStore = useSidebarStore();
const { open } = storeToRefs(sidebarStore);
import { useDisplay } from "vuetify";

const { xs } = useDisplay();

const isItMobile = computed(() => {
  return xs.value ? "right" : "left";
});

const handleDrawer = (isOpen) => {
  open.value = isOpen;
};

const navigationRoutes = [
  {
    path: "/dashboard",
    label: "Home",
    name: "home",
    icon: "mdi mdi-home-outline",
  },
  {
    path: "/account",
    label: "Account",
    name: "account",
    icon: "mdi mdi-account-outline",
  },
  {
    path: "/professionals",
    label: "Professionals",
    name: "professionals",
    icon: "mdi mdi-grass",
  },
  {
    path: "/properties",
    label: "Properties",
    name: "properties",
    icon: "mdi mdi-domain",
  },
  {
    path: "/invoices",
    label: "Invoices",
    name: "invoices",
    icon: "mdi mdi-receipt-text",
  },
  {
    path: "/payments",
    label: "Payments",
    name: "payments",
    icon: "mdi mdi-credit-card-outline",
  },
  {
    path: "/chats",
    label: "Chats",
    name: "chats",
    icon: "mdi mdi-forum-outline",
  },
  {
    path: "/services",
    label: "Services",
    name: "services",
    icon: "mdi mdi-wrench-outline",
  },
  {
    path: "/reschedule",
    label: "Reschedule",
    name: "reschedule",
    icon: "mdi mdi-calendar",
  },
];
</script>

<style lang="scss">
@import "@/assets/style.scss";
@import "@/assets/mixins.scss";

.sidebar-logo {
  width: 50px;
}

.v-list-item--active {
  .v-list-item-title {
    color: $SP200 !important;
  }

  .v-list-item__prepend i {
    color: $SP200 !important;
  }
}

.v-list-item-title {
  font-weight: 400 !important;
  font-size: 1rem !important;
}

.v-layout {
  @include minWidth(768px) {
    width: 25%;
  }
}

.v-navigation-drawer__scrim {
  height: auto !important;

  @include minWidth(768px) {
    background-color: white !important;
    opacity: 0;
  }
}

.v-navigation-drawer {
  background-color: $SP2800 !important;

  @include minWidth(768px) {
    width: 25% !important;
    transform: none !important;
    box-shadow: none !important;
  }
}

.v-list-item__prepend i.v-icon {
  margin-right: 15px !important;
}

h1 {
  font-weight: 400;
  color: $SP1500;
  font-size: 2rem !important;

  & span {
    color: $SP400;
  }
}
</style>
