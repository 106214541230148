import { defineStore } from 'pinia';

export const useSidebarStore = defineStore('sidebar', {
  state: () => ({
    open: false,
  }),
  getters: {
    getSideBarState() {
      return this.open;
    },
  },
});